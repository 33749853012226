$(function () {
    setHeaderScroll();
    hamburger();

});


//Lightcase
$(document).ready(function() {
	$('a[data-rel^=lightcase]').lightcase();
});
